import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom';
import {setshowSidebarFalse} from '../../redux/Slice/changeState'

const AllCategories = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const documentData = useSelector((state) => state.master.documentData);
  // console.log("documentData", documentData)

  const handlecategory = (url) => {
    // showSidebar()
    navigate(`/document/${url}`)
    dispatch(setshowSidebarFalse())
  }


  return (
    <div className='main_category'>
      {
        documentData.data.map((ele, i) => {
          return (
            <div className='category' key={ele.id}>
              <div className='category-leftside' >
                <h5 className='category-leftsidetitle'>{ele.category_name}</h5>
                {
                  ele.posts.length > 0 &&
                  ele.posts.map(ele1 => {
                    return (
                      <NavLink to={`/document/${ele1.slug}`} className={"text-decoration-none"} onClick={() => handlecategory(ele.slug)}>
                        <h5 className='post-leftsidetitle'>{ele1.title}</h5>
                      </NavLink>
                    )
                  })
                }
              </div>
              {/* {
                ele.child.length > 0 &&
                <div className='category-rightside'>
                  <div className='category-rightside-icon'><HiChevronRight color="black" size="25" /></div>
                </div>
              } */}
            </div>
          )
        })
      }
    </div>
  )
}

export default AllCategories
