import React, { useEffect, useRef, useState } from 'react';
import {Form, InputGroup} from 'react-bootstrap'
import { AiOutlineSearch } from 'react-icons/ai';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {setshowsearchdataFalse, setshowsearchdataTrue} from '../../redux/Slice/changeState'
import { fetchSearchDocument } from '../../redux/Slice/search';

const SearchBar = () => {
    const searchBarRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const seachshow = useSelector((state)=> state.toggle)
    const seachdata = useSelector((state)=> state.search)
    const [value, setValue] = useState("")

    const handleChange = (e) => {
        e.preventDefault();
        // setValue(e.target.value)
        const input_data = { search: e.target.value };
        dispatch(fetchSearchDocument(input_data));
    };

    const handleNavigate = () => {
        dispatch(setshowsearchdataFalse());
        setValue("")
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                dispatch(setshowsearchdataFalse());
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dispatch]);
    
    return (
        <div
            className='Header-search-bar'
            ref={searchBarRef}
            onMouseEnter={() => dispatch(setshowsearchdataTrue())}
            onMouseLeave={() => dispatch(setshowsearchdataFalse())}
            tabIndex="0"
        >
            <InputGroup>
                <InputGroup.Text id="search-button" >
                    <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                    placeholder="Search"
                    aria-label="Username"
                    size="md"
                    onChange={handleChange}
                    defaultValue={value}
                />

            </InputGroup>

            {seachshow.showsearchdata && (

                <div
                    className="searchDetail"
                    onMouseEnter={() => dispatch(setshowsearchdataTrue())}
                    onMouseLeave={() => dispatch(setshowsearchdataFalse())}
                >
                    {seachdata.searchPost != null && seachdata.searchPost.data.length > 0 ? (
                        seachdata.searchPost.data.map((ele, index) => {
                            const detail_url = ele.slug;
                            return (
                                <NavLink to={`/document/${detail_url}`}
                                    key={index}
                                    className="text-decoration-none"
                                    onClick={handleNavigate}
                                >
                                    <div className="searchbar-product-list">
                                        <h6 className="search-title-products">{ele.title}</h6>
                                        <div dangerouslySetInnerHTML={{ __html: ele.short_description }} />
                                    </div>
                                </NavLink>
                            );
                        })
                    ) :
                        <div className="searchbar-product-list">No Record Found</div>
                    }
                </div>
            )}
        </div>
    )
}

export default SearchBar
