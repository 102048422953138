import { configureStore } from '@reduxjs/toolkit'
import masterReducer from './Slice/master'
import toggleReducer from './Slice/changeState'
import searchReducer from './Slice/search'

const store = configureStore({
  reducer: {
    master : masterReducer,
    toggle: toggleReducer,
    search: searchReducer,
  },
})

export default store;