import React, { Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ScrollToTop } from '../hooks'
import * as view from '../views'
import * as ROUTES from '../constant/routes'
import * as layout from '../component/layout'
import { DocHome } from '../component/common';

const Approuters = () => {
    return (
        <Router>
            <>
                <Suspense>
                    <Routes>
                        <Route path={ROUTES.Home} element={
                            <ScrollToTop>
                                <layout.DesktopLayout>
                                    <view.Home />
                                </layout.DesktopLayout>
                            </ScrollToTop>
                        }>
                        </Route>
                        <Route path={ROUTES.Contact} element={
                            <ScrollToTop>
                                <layout.DesktopLayout>
                                    <view.ContactUs />
                                </layout.DesktopLayout>
                            </ScrollToTop>
                        }>
                        </Route>
                        <Route path={ROUTES.detail} element={
                            <ScrollToTop>
                                <layout.DesktopLayout>
                                    <view.Detailpage />
                                </layout.DesktopLayout>
                            </ScrollToTop>
                        }>
                        </Route>
                        <Route path={ROUTES.Pricing} element={
                            <ScrollToTop>
                                <layout.DesktopLayout>
                                    <view.Pricing />
                                </layout.DesktopLayout>
                            </ScrollToTop>
                        }>
                        </Route>
                        <Route
                            element=
                            {
                                <ScrollToTop>

                                    <layout.DocLayout>
                                        <DocHome />
                                    </layout.DocLayout >
                                </ScrollToTop>
                            }
                            exact
                            path={ROUTES.Document}
                        />
                        <Route
                            element=
                            {
                                <ScrollToTop>
                                    <layout.DocLayout>
                                        <view.DocDetail
                                        />
                                    </layout.DocLayout>
                                </ScrollToTop>
                            }
                            exact
                            path={ROUTES.Docdetails}
                        />
                        <Route
                            element={
                                <ScrollToTop>
                                    <view.Search />
                                </ScrollToTop>
                            }
                            exact
                            path={ROUTES.Searchsuggestion}
                        />

                    </Routes>
                </Suspense>
            </>
        </Router>
    )
}

export default Approuters
