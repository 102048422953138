import React from 'react'
import bg from '../../images/background2.webp'
import ios from '../../images/app-store.svg'
import play from '../../images/google-play.svg'
// import GetInTouch from './GetInTouch'

const CallToAction = () => {
  return (
    <>
      <div className="cta" style={{ backgroundImage: `url('${bg}')` }}>
            <div className="container">
                <div className='space-80'></div>
                <div className="row align-items-center">
                  <h1 class="text-center">Start your improvement momement</h1>
                </div>
                <div className='space-20'></div>
                <div className="row align-items-center">
                  <div class="app-section">
                    <img src={ios} className='ios-button' alt=""></img>
                    <img src={play} className='' alt=""></img>
                  </div>
                </div>
            </div>
            <div className='space-80'></div>

            

        </div >
    </>
  )
}

export default CallToAction
