import React from 'react'
import * as common from '../component/common'

const Pricing = () => {
  
  return (
    <>
      <common.Subscription />
      <div className='space-30'></div>
      <common.ContactComponent />
    </>
  )
}

export default Pricing
