import React, { useEffect } from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as common from '../component/common'
import { Helmet } from "react-helmet";
import { fetchBlogDetail } from '../redux/Slice/master';

const Detailpage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.master.blogData)

  useEffect(() => {
    const input_data = {
      slug: slug,
    };
    dispatch(fetchBlogDetail(input_data));
  }, [slug])

  

  return (
    <div className=''>

      {
        blog != null  ?
          <>

            <Helmet>
              <title>{blog.productList[0].title}</title>
              <meta property='description' content={blog.productList[0].short_description} />
              <meta property='keywords' content={blog.productList[0].tags} />
              <meta property='title' content={blog.productList[0].title} />
              <meta property="og:title" content={localStorage.getItem("title") + " - " + blog.productList[0].title} />
              <meta property="og:description" content={ blog.productList[0].short_description} />
              <meta property="og:type" content="website" />
            </Helmet>

            <common.BlogSlider data={blog.productList[0]}/>

            <div className='detail-page'>
              <Container>
                  <div className='space-90'></div>
                  <Row>
                    <Col xl={9} lg={8} className="mb-5 mb-lg-0 detail-body">
                      <div dangerouslySetInnerHTML={{ __html: blog.productList[0].body }} />
                    </Col>
                    <Col xl={3} lg={4} className='service-widget-area'>
                        <common.RightSideComponent slug={slug} />
                    </Col>
                  </Row>
              </Container>
            </div>

            <div className='space-30'></div>

           
            <div className="space-30"></div>
            <common.ContactComponent />

          </>

        : <div className='custom-height'></div>



      }

      
    </div>
  )
}
export default Detailpage;
