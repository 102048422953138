import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

const DocHome = () => {
  return (
    <div className='document-homepage'>
      <Container>
        <Row>
          <Col lg={2} md={12} sm={12}></Col >
          <Col lg={10} md={12} sm={12}>
            <div >
              <h1>Auditsbay Documentation</h1>
              <h6>Auditsbay is a comprehensive auditing platform designed to streamline audit processes, enhance compliance, and improve efficiency. It offers features like customizable audit templates, real-time reporting, and automated task management. With Auditsbay, organizations can easily manage, track, and analyze audits, ensuring consistent quality and adherence to regulatory standards.</h6>
              <Row>
                <Col lg={4}  md={12} sm={12}>
                  <div className='category-section'>
                    <h2>Less paperwork</h2>
                    <p>No more paperwork or spreadsheets. Conduct mobile inspections on your preferred device, even offline. Capture every detail efficiently: our customers report up to 5x faster inspections!</p>
                  </div>
                </Col>
                <Col lg={4}  md={12} sm={12}>
                  <div className='category-section'>
                    <h2>Everyone in sync</h2>
                    <p>From field teams to management, everyone knows when and what to do, and who is accountable.</p>
                  </div>
                </Col>
                <Col lg={4}  md={12} sm={12}>
                  <div className='category-section'>
                    <h2>Real-time data in one place</h2>
                    <p>Resolve issues quicker, spot trends and get insights to improve operations</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DocHome
