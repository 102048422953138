import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Accordian from './Accordian'

const Faqs = () => {
    const faqs = useSelector((state) => state.master.faqData)
  return (
    <div className='container'>
            <div class="ot-heading text-center">
              
                <div class="space-30"></div>
                <div class="space-30"></div>
                <span class="is_highlight">FAQ'S</span>
                <h3 class="main-head">Frequently asked questions</h3>
                
            </div>
            <div className='space-20'></div>
            <Row>
                <Col className='frequently-asked-col' lg={6} md={12} sm={12}>
                    {
                        faqs != null && 
                        <Accordian data={faqs.pagelist.slice(0, 5)} />
                    }

                </Col>
                <Col className='frequently-asked-col' lg={6} md={12} sm={12}>
                    {
                        faqs != null &&
                        <Accordian data={faqs.pagelist.slice(5, 10)} />
                    }

                </Col>
                <div class="space-30"></div>
            </Row>
        </div>
  )
}

export default Faqs
