import React from 'react'
import {DesktopNavbar, MobileNavbar} from '../common'
import { useMediaQuery } from 'react-responsive'

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  return (
    <>
      <div id="site-header" className="site-header header-static sticky-header">
      {!isMobile && 
        <DesktopNavbar />
      }
        {isMobile && 
          <MobileNavbar />
        }
      </div>
    </>
  )
}

export default Header
