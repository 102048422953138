import React from 'react'

const GetInTouch = () => {
  return (
   
        <div class="ot-button">
            <a href="#" class="octf-btn octf-btn-main" data-bs-target="#exampleModal" data-bs-toggle="modal">Get In Touch </a>
        </div>
  
  )
}

export default GetInTouch
