import React from 'react'
import { Accordion } from 'react-bootstrap'

const Accordian = ({ data }) => {
    return (
        <div>
            {/* defaultActiveKey="0" */}
            <Accordion className='accordian-main-div'  >
                {
                    data.map((ele, index) => {
                        return (

                            <Accordion.Item key={index} className='accordian-data-div' eventKey={String(index)}>
                                <Accordion.Header className='accordian-header'>{ele.questions}</Accordion.Header>
                                <Accordion.Body>
                                    {ele.answers}
                                </Accordion.Body>
                            </Accordion.Item>


                        )
                    })
                }
            </Accordion>
        </div>
    )
}

export default Accordian
