import React,{useEffect} from 'react'
import {NavLink, useSearchParams} from 'react-router-dom'
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { SiMinutemailer } from "react-icons/si";
import {useSelector} from "react-redux"

const RightSideComponent = ({slug}) => {
    const homedata = useSelector(state => state.master.homeData);
    
    const [searchParams] = useSearchParams();
    var param = searchParams.get('q');

    useEffect(()=>{
    },[slug, param])
  return (
    <>
      {/* <aside className='service-widget service_widget_category'>
        <h6 className='service-widget-title'>Shopersbay</h6>
        <ul>
          {homedata != null && 
                     homedata.postcategorydataList &&
                     homedata.postcategorydataList[param] &&
                     homedata.postcategorydataList[param].filter(ele => ele.slug !== slug).map((ele, i) => (
                        <li key={i}>
                            <NavLink to={`/blogdetail/${ele.slug}?q=${param}`}>{ele.title}</NavLink>
                        </li>
                    ))}

       
        
        </ul>
      </aside> */}
      {/* <aside className='service-widget service_widget_category'>
        <h6 className='service-widget-title'>Other Products</h6>
        <ul>
            <li><NavLink to="https://auditsbay.com/" target="_blank">Auditsbay</NavLink></li>
            <li><NavLink to="https://login.shopersbay.com/" target="_blank">URHR Service</NavLink></li>
        </ul>
      </aside> */}
      <aside className='service-widget'>
        <h6 className='service-widget-title'>Contacts</h6>
        <div className='d-flex'>
            <div className='widget-service-icon'>
                <FaMapMarkerAlt />
            </div>
            <div className='widget-service-text'>
                <h6>Visit Us Daily</h6>
                <p>{localStorage.getItem("domain_address")}</p>
            </div>
        </div>
        <div class="space-10"></div>
        <div class="space-6"></div>
        <div className='d-flex'>
            <div className='widget-service-icon'>
                <NavLink to={`https://wa.me/${localStorage.getItem("vendor_contact")}`} target="_blank">
                  <RiWhatsappFill />
                </NavLink>
            </div>
            <div className='widget-service-text'>
                <h6>Have Any Questions?</h6>
                <NavLink className="text-decoration-none" to={`https://wa.me/${localStorage.getItem("vendor_contact")}`} target="_blank"><p>{localStorage.getItem("vendor_contact")}</p></NavLink>
            </div>
        </div>
        <div class="space-10"></div>
        <div class="space-6"></div>
        <div className='d-flex'>
            <div className='widget-service-icon'>
                <a href={`mailto:${localStorage.getItem("vendor_email")}`}>
                  <SiMinutemailer />
                </a>
            </div>
            <div className='widget-service-text'>
                <h6>Mail Us</h6>
                <a className="text-decoration-none" href={`mailto:${localStorage.getItem("vendor_email")}`}>
                  <p>{localStorage.getItem("vendor_email")}</p>
                </a>
            </div>
        </div>
        <div class="space-10"></div>
        <div class="space-6"></div>
      </aside>
    </>
  )
}

export default RightSideComponent
