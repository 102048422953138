import React from 'react'
import * as common from '../component/common'
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";

const Home = () => {
  
  const { homedata, faqData, subscriptionData, masterdata } = useSelector(state => ({
    homedata: state.master.homeData,
    faqData: state.master.faqData,
    subscriptionData: state.master.subscriptiondata,
    masterdata: state.master.data,
  })); 


  return (
    <>
      <Helmet>
        <title>{masterdata != null && masterdata.data.vendor.domain_title}</title>
        <meta property='description' content={masterdata != null && masterdata.data.vendor.domain_descrption} />
        <meta property='keywords' content={masterdata != null && masterdata.data.vendor.domain_keyword} />
        <meta property='title' content={masterdata != null && masterdata.data.vendor.domain_title} />
        <meta property="og:title" content={masterdata != null && masterdata.data.vendor.domain_title} />
        <meta property="og:description" content={masterdata != null && masterdata.data.vendor.domain_descrption} />
        <meta property="og:type" content="website" />
      </Helmet>
      <common.Slider data={(homedata != null && homedata.data.postcategorydataList.hasOwnProperty("Features")) ? homedata.data.postcategorydataList.Features : null }/>
      {
      homedata != null && (
        <>
          {homedata.data.postcategorydataList.hasOwnProperty("HomeAbout") && (
            <common.OneImage data={homedata.data.postcategorydataList.HomeAbout[0]} />
          )}
          {homedata.data.postcategorydataList.hasOwnProperty("HomeAboutFeature") && (
            <common.Feature data={homedata.data.postcategorydataList.HomeAboutFeature} />
          )}
          {homedata.data.postcategorydataList.hasOwnProperty("Benifits") && (
            <common.Benifits data={homedata.data.postcategorydataList.Benifits} />
          )}
          <common.CallToAction />
          {
            subscriptionData != null && <common.Subscription />
          }
          <div className='space-60'></div>
          {
            faqData!=null && <common.Faqs />
          }
          
          <div className='space-60'></div>
          <common.ContactComponent />
        </>
      )
      }
      
    </>
  )
}

export default Home
