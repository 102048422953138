import React, {useEffect} from 'react'
import fav from '../../images/logo/favicon.png'
import { useSelector } from 'react-redux';

const Loader = () => {
    const loaderstatus = useSelector(state => state.toggle.loaderstatus);

    useEffect(() => {
        if (loaderstatus === true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

    }, [loaderstatus])

    return (
        <>
        {
            loaderstatus && 

            <div className="preloader">
                <div className='preloader-div'>
                    <img src={fav} alt="" className='w-50 img-fluid loader-image'></img>
                </div>
            </div>
        }
        </>
    )
}

export default Loader