import React, {useEffect} from 'react'
import './App.css';
import Approuters from './routers/Approuters';
import { useDispatch } from 'react-redux';
import {fetchMasterData, fetchNavigation, fetchHomeData, fetchDocumentation, fetchSubscription, fetchFaq} from './redux/Slice/master'

function App() {
  const dispatch = useDispatch()
  
  useEffect(()=>{
    dispatch(fetchMasterData())
    dispatch(fetchNavigation())
    dispatch(fetchHomeData())
    dispatch(fetchDocumentation())
    dispatch(fetchSubscription())
    dispatch(fetchFaq())
  },[])

  return (
    <>
      <Approuters />
    </>
  );
}

export default App;
