import React from 'react'
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { BsSearch } from 'react-icons/bs';
import logo from "../../images/logo/auditsbay_docs_logo.png"
import {DocMobileNavigation, SearchBar} from '../common'
const DocHeader = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const state = useSelector((state) => state.master.navigation);
    // console.log("DocHeader", state)
    return (
        <div className="header-main">
                <div className="logo-header">
                    <NavLink to="/">
                        <img className="logo-image" src={logo} alt=""></img>
                    </NavLink>
                </div>
                {

                    !isMobile &&
                    <SearchBar />

                }

                <div className="d-flex">
                    {
                        !isMobile && <div className="header-cat">
                            {
                                state != null && (state.result.filter(ele => ele.list_key === 'docs_menu')).map(ele => {
                                    return (
                                        <div className="header-car-content" ><NavLink className="text-decoration-none" to={`${ele.url}`}>{ele.name}</NavLink></div>
                                    )
                                })
                            }



                        </div>
                    }
                    {
                        !isMobile &&
                        <div className="header-buttion-signin-up">
                            <div className="header-button-signin">
                                <NavLink to="https://login.shopersbay.com/auditsbay" target="_blank">
                                Sign In
                                </NavLink>
                            </div>
                            <div className="header-button-signup">
                                <NavLink to="https://login.shopersbay.com/signup?q=auditsbay" target="_blank">
                                Sign Up
                                </NavLink>
                            </div>
                        </div>

                    }
                </div>


                {
                    isMobile &&
                    <div className="d-flex">
                        <NavLink to="/searchsuggestion" className="text-decoration-none">
                            <div className='me-3'>
                                <BsSearch size="20" />
                            </div>
                        </NavLink>
                        <DocMobileNavigation />
                    </div>
                }


            </div>
    )
}

export default DocHeader
