import React from 'react'
import Typewriter from 'typewriter-effect';
import bg from '../../images/audits_header.jpg'
import { VscGraph } from "react-icons/vsc";
import { RiTeamFill } from "react-icons/ri";
import { MdInsights } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import GetInTouch from './GetInTouch';

const Slider = ({data}) => {
    return (
        <>
            <div className="finance-banner" style={{ backgroundImage: `url('${bg}')` }}>
                <div className="container">
                    <div className="space-70"></div>
                    <div className="row">
                        <div className="col-lg-7 pr-xl-0">
                            <h2>All-in-One<br /> Platform For<br />
                                <span style={{ color: "#008000" }}>
                                    <Typewriter
                                        options={{
                                            strings: ['E-Commerce','Audits', 'Asset Management', 'HRIS'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </span>
                            </h2>
                            <div className="ot-div">
                            <GetInTouch />
                            <div className="box-s1 icon-left bline-no">
                                <div className="icon-box icon-box-2">
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="space-120"></div>
                </div>
            </div>
            <div className='feature'>
                    <div className="container">
                        <div className="row justify-content-center">
                            {
                                data != null && data.map((ele, i)=>{
                                    return (
                                        <div className="col-lg-4 col-md-6 mb-2 mb-md-0" key={i}>
                                            <NavLink className={"text-decoration-none"} to={`/detail/${ele.slug}`}>
                                                <div className="icon-box icon-box-2 feature-box ">
                                                    <div className="icon-main threecard">
                                                        { ele.slug === 'collect-data' && <VscGraph /> }
                                                        { ele.slug === 'guide-teams' && <RiTeamFill /> }
                                                        { ele.slug === 'gain-clarity' && <MdInsights /> }
                                                    </div>
                                                    <div className="content-box">
                                                        <h3 className="title-box">{ele.title}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: ele.description }} />
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    )
                                })
                                
                            }
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Slider
