import React from 'react'
import { useSelector } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap"
import {DocHeader, DocSidebar} from '../common';

const DocLayout = ({ children }) => {
    const state = useSelector((state) => state.toggle);
    const homedata = useSelector((state) => state.master.homeData)
  return (
    <>
      <DocHeader />
      <DocSidebar />
      <div className='doc-div'>
        {children}
        {/* {
          state.dochomedata &&
            <Container fluid>
              <Row>
                  <Col lg={2}></Col>
                  <Col lg={10}>
                      {
                        homedata != null &&
                        <div dangerouslySetInnerHTML={{ __html: homedata.data.postcategorydataList.Document[0].description }} />
                      }
                  </Col>
              </Row>
            </Container>
        } */}
      </div>

    </>
  )
}

export default DocLayout
