import React, {useState} from 'react'
import { useMediaQuery } from 'react-responsive';
import {Col} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {setdochomedataTrue, setdochomedataFalse} from '../../redux/Slice/changeState'

const DocSidebar = () => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const documentData = useSelector((state) => state.master.documentData);
    const [selectButton, setSelectButton] = useState(true);
    

    const Category = ({...props}) => {
        // Extracting specific props you might want to use directly
        const { category, subcats, posts } = props;
        return (
            <div className='catsidebar'>
                <div style={{ cursor: 'pointer' }} className='catsidebar-cattitle'>
                    {category}
                </div>
                {subcats.map(subcat => (
                    <div key={subcat.name}>
                        <div className='document-subcategory'>
                            <div className='catsidebar-subtitle'>
                                <NavLink className={"text-decoration-none"} to={`/document/${subcat.slug}`} onClick={() => dispatch(setdochomedataFalse())}>
                                    {subcat.subcategory_name}
                                </NavLink>
                            </div>
                            {subcat.posts.map(post => (
                                <NavLink className={"text-decoration-none"} to={`/pages/docs/${post.slug}`}>
                                    <div className='subcategorydata' onClick={() => dispatch(setdochomedataFalse())} key={post.id}>{post.title}</div>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                ))}
                <div className='document-subcategory'>
                    {posts.map(post => (
                        <NavLink className={"text-decoration-none"} to={`/document/${post.slug}`}>
                            <div className='subcategorydata' onClick={() => dispatch(setdochomedataFalse())} key={post.id}>{post.title}</div>
                        </NavLink>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <>
            {!isMobile && selectButton &&
                <Col lg={2} className='cartside-bar-main'>
                    {documentData != null && documentData.data.map(ele => (
                        <Category
                            key={ele.category_id}
                            category={ele.category_name}
                            subcats={ele.child}
                            catslug={ele.slug}
                            posts={ele.posts}
                        />
                    ))}
                </Col>

            }
        </>
    )
}

export default DocSidebar
