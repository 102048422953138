import React, {useState, useEffect} from 'react'
import backgroundImage from '../../images/subscription_bg.webp'
import {Container, Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {NavLink} from 'react-router-dom'

const Subscription = () => {
    const dispatch =useDispatch();
    const subscriptiondata = useSelector((state) => state.master.subscriptiondata)
    
    const montlydata = subscriptiondata != null && subscriptiondata.data.filter(ele => ele.type === "Month");
    const yeardata = subscriptiondata != null &&  subscriptiondata.data.filter(ele => ele.type === "Year");
    const [isYearlyBilling, setIsYearlyBilling] = useState(true);
    const [subfilterdata, setSubFilterData] = useState([])
    const onchange = () => {
        setIsYearlyBilling(!isYearlyBilling);
        setSubFilterData(!isYearlyBilling ? montlydata : yeardata)
    }

    useEffect(() => {
        // Initially set the data to monthly
        setSubFilterData(isYearlyBilling ? montlydata : yeardata)

    }, [subscriptiondata]);

    // console.log("isYearlyBilling", isYearlyBilling)
    return (
        <>
            <div id="price" className="pricing-table-section" style={{ backgroundImage: `url('${backgroundImage}')`}} >
                <Container>
                    <div className="space-90"></div>
                    <Row>
                        <Col lg={8} className='offset-lg-2 text-center'>
                            <div className='ot-heading'>
                                <span className="is_highlight">flexible pricing plans</span>
                                <h3 className="main-head text-white">Choose The Best Plan</h3>
                            </div>
                            <div className="space-20"></div>
                            <p className="text-white px-xl-5 mx-xl-5">Choose the plan that caters to your specific requirements, ensuring a seamless experience that propels you towards success.</p>
                            <div className="space-30"></div>
                            <div className="ot-switcher">
                                <span className="l-switch  text-white">Billed Monthly</span>
                                <label className={`switch form-check-label`} htmlFor='toggleMonth'>
                                    <input type="checkbox" id="toggleMonth" onChange={onchange} style={{opacity:0}} />
                                    <span className={`slider round ${isYearlyBilling ? "activeclass" : "inactiveclass"}`}></span>
                                </label>
                                <span className="r-switch  text-white">Billed Yearly</span>
                            </div>
                            <div className='space-60'></div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center pricing-monthly'>
                    {
                        subfilterdata.length > 0 &&
                        subfilterdata.map((ele, i) => {
                            return(
                                <Col xl={3} md={6} className='mb-5 mb-xl-0' key={i}>
                                <div className="ot-pricing-table ">
                                    <div className="layer-behind"></div>
                                    <div className="inner-table">
                                        <div className="title-table"><span>{ele.name}</span></div>
                                        {
                                            ele.price !== '0' ?
                                           <> <div className="amt"><sup>₹</sup> {ele.price}</div><h6>{ele.type}</h6></> : ""
                                        }
                                       
                                        <div className="short-text" dangerouslySetInnerHTML={{ __html: ele.description }}/>
                                     {
                                        ele.price !== '0' ?
                                        <button type="button" 
                                        className="octf-btn octf-btn-border" ><NavLink className={"text-decoration-none"} to="/signup?q=shopersbay" target="_blank">Get Started</NavLink></button>
                                        :
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        
                                        className="octf-btn octf-btn-border" >Get Quote</button>

                                     }
                                       
                                    </div>
                                </div>
                            </Col>
                            )
                          
                        })
                    }

                </Row>
                </Container>
            </div>
        </>
    )
}

export default Subscription
