import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import Apis from '../../services/Apis';

const {getNavigationlist, getMasterDataService, getDocumentationlist, getHomeDatalist, SaveContactForm, getSubscriptionlist, getFaqList, getBlogdetail} = Apis();

export const fetchMasterData = createAsyncThunk("fetchMasterData", async () => {
    const response = await getMasterDataService();
    return response.data;
});

export const fetchNavigation = createAsyncThunk("fetchNavigation", async () => {
    const response = await getNavigationlist();
    return response.data;
});

export const fetchDocumentation = createAsyncThunk("fetchDocumentation", async () => {
    const response = await getDocumentationlist();
    return response.data;
});

export const fetchHomeData = createAsyncThunk("fetchHomeData", async () => {
    const response = await getHomeDatalist();
    return response.data;
});

export const fetchSubscription = createAsyncThunk("fetchSubscription", async () => {
    const response = await getSubscriptionlist();
    return response.data;
});

export const fetchFaq = createAsyncThunk("fetchFaq", async () => {
    const response = await getFaqList();
    return response.data;
});

export const fetchBlogDetail = createAsyncThunk("fetchBlogDetail", async (input_data) => {
    const response = await getBlogdetail(input_data);
    return response.data;
});

// Async thunk to handle the async operation
export const saveContact = createAsyncThunk(
    'saveContact',
    async ({ input_data, setFormValues }, { dispatch }) => {
      try {
        const response = await SaveContactForm(input_data);
  
        if (response.data.status === "success") {
          
          document.getElementById("close-modal").click();
          
          setFormValues({
            name: "",
            phone: "",
            email: "",
            subject: "",
            message: "",
          });
  
          return response.data;
        } else {
          throw new Error('Failed to save contact form data');
        }
      } catch (error) {
        throw error;
      }
    }
);


const masterSlice = createSlice({
    name:"master",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        navigation: null,
        documentData: null,
        homeData: null,
        contactData: false,
        subscriptiondata:null,
        faqData: null,
        blogData: null,
    },
    extraReducers: (builder) => {
        //master data
        builder.addCase(fetchMasterData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMasterData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchMasterData.rejected, (state, action) => {
            state.isError= true;
        });

        //Navigation List
        builder.addCase(fetchNavigation.pending, (state, action) => {
            state.isLoading=true;
        });
        builder.addCase(fetchNavigation.fulfilled, (state,action) => {
            state.isLoading=false;
            state.navigation=action.payload;
        });
        builder.addCase(fetchNavigation.rejected, (state, action) => {
            state.isError=true;
        });

        //Documentation List
        builder.addCase(fetchDocumentation.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDocumentation.fulfilled, (state, action) => {
            state.isLoading = true;
            state.documentData = action.payload;
        });
        builder.addCase(fetchDocumentation.rejected, (state, action) => {
            state.isError=true;
        });

        //Home page List
        builder.addCase(fetchHomeData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHomeData.fulfilled, (state, action) => {
            state.isLoading = true;
            state.homeData = action.payload;
        });
        builder.addCase(fetchHomeData.rejected, (state, action) => {
            state.isError=true;
        });

        //Save contact
        builder.addCase(saveContact.pending, (state, action) => {
            state.contactData = false;
        });
        builder.addCase(saveContact.fulfilled, (state, action) => {
            state.contactData = true;
        });
        builder.addCase(saveContact.rejected, (state, action) => {
            state.contactData = false;
        });

        //Subscription list
        builder.addCase(fetchSubscription.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchSubscription.fulfilled, (state, action) => {
            state.isLoading = true;
            state.subscriptiondata = action.payload;
        });
        builder.addCase(fetchSubscription.rejected, (state, action) => {
            state.isLoading = false;
        });

        //Faq List
        builder.addCase(fetchFaq.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFaq.fulfilled, (state, action) => {
            state.isLoading = true;
            state.faqData = action.payload;
        });
        builder.addCase(fetchFaq.rejected, (state, action) => {
            state.isLoading = false;
        });

        //Blog Detail
        builder.addCase(fetchBlogDetail.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchBlogDetail.fulfilled, (state, action) => {
            state.isLoading = true;
            state.blogData = action.payload;
        });
        builder.addCase(fetchBlogDetail.rejected, (state, action) => {
            state.isLoading = false;
        });
    }
});

export default masterSlice.reducer;