import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from '../../images/logo/auditsbay_logo.png'
import { useSelector } from "react-redux"
import useMediaQuery from 'react-responsive'

const DesktopNavbar = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  const state = useSelector((state) => state);

  return (
    <>
      <div className="header-main">
        <div className="logo-header">
          <NavLink to="/">
            <img className="logo-image" src={logo} alt=""></img>
          </NavLink>
        </div>
        <div className="d-flex">
          {
            !isMobile && <div className="header-cat">
              {
                state.master.navigation != null && state.master.navigation.result.length > 0 && (state.master.navigation.result).filter(ele => ele.list_key === 'main_menu').map((ele, i) => {
                  return (
                    <NavLink className={"text-decoration-none"} to={`${ele.url} `} target="_blank" key={i}>
                      <div className="header-car-content">{ele.name}</div>
                    </NavLink>

                  )
                })
              }



            </div>
          }
          {
            !isMobile &&
            <div className="header-buttion-signin-up">
              <div className="header-button-signin">
                <NavLink className={"text-decoration-none "} to="https://login.shopersbay.com/auditsbay" target="_blank">
                  Sign In
                </NavLink>

              </div>
              <div className="header-button-signup">
                <NavLink className={"text-decoration-none"} to="https://login.shopersbay.com/signup?q=auditsbay" target="_blank">
                  Sign Up
                </NavLink>

              </div>
            </div>

          }
        </div>



      </div>
    </>
  )
}

export default DesktopNavbar
